// src/router/index.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';

Vue.use(VueRouter); // 必须显式使用 Vue.use()

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
];

const router = new VueRouter({
  mode: 'history', // 使用 HTML5 的历史模式
  routes,
});

export default router;
