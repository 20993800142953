<template>
    <div>
        <!-- 横幅部分 -->
        <div class="hero-banner bg-light py-5 text-center mt-5">
            <h1 class="display-6 fw-bold">Accurate Historical Crypto Price Data</h1>
            <p class="text-muted">Download reliable K-line (OHLCV) data in CSV format for analysis.</p>
        </div>

        <!-- 下载区域 -->
        <div class="container py-5">
            <!-- 参数选择 -->
            <div class="row g-3">
                <div class="col-md-4">
                    <label class="form-label">Exchange</label>
                    <select v-model="selectedExchange" class="form-select">
                        <option v-for="exchange in exchanges" :key="exchange.value" :value="exchange.value">
                            {{ exchange.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label class="form-label">Currency Pair</label>
                    <select v-model="selectedPair" class="form-select">
                        <option v-for="pair in pairs" :key="pair.value" :value="pair.value">
                            {{ pair.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label class="form-label">Timeframe</label>
                    <select v-model="selectedTimeframe" class="form-select">
                        <option v-for="item in timeframes" :key="item.value" :value="item.value">{{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <!-- 时间选择 -->
            <div class="row g-3 mt-2">
                <div class="col-md-6">
                    <label class="form-label">Start Time</label>
                    <input type="date" v-model="startDate" class="form-control" :max="endDate" />
                </div>
                <div class="col-md-6">
                    <label class="form-label">End Time</label>
                    <input type="date" v-model="endDate" class="form-control" :min="startDate" :max="currentDate" />
                </div>
            </div>

            <!-- 下载按钮 -->
            <div class="text-center mt-4">
                <button @click="startDownload" class="btn btn-danger px-5 fw-bold"
                    :disabled="isProcessing || !isDateValid">
                    {{ isProcessing ? "Processing..." : "Download" }}
                </button>
            </div>

            <!-- 支付模态框 -->
            <div v-if="showPaymentModal" class="modal fade show d-block" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Payment Required</h5>
                            <button type="button" class="btn-close" @click="cancelPayment"></button>
                        </div>
                        <div class="modal-body">
                            <p>Download Rules:</p>
                            <ul>
                                <li>Today's data is free (UTC time)</li>
                                <li>Historical data: $0.01 per 1,000 bars</li>
                                <li>Minimum charge: $1.00</li>
                            </ul>
                            <p class="fw-bold">Total Bars: {{ totalBars.toLocaleString() }}</p>
                            <p class="fw-bold">Total Cost: ${{ paymentCost.toFixed(2) }}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cancelPayment">Cancel</button>
                            <button type="button" class="btn btn-primary" @click="proceedToPayment">Pay with
                                PayPal</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showPaymentModal" class="modal-backdrop fade show"></div>

            <!-- 付款成功提示 -->
            <div v-if="showPaymentSuccess" class="alert alert-success mt-4">
                <h5 class="fw-bold">Payment Successful!</h5>
                <p>Your payment has been processed successfully.</p>
                <p>Task ID: <strong>{{ taskId }}</strong></p>
                <p>Total Bars: <strong>{{ totalBars.toLocaleString() }}</strong></p>
                <p>Total Cost: <strong>${{ paymentCost.toFixed(2) }}</strong></p>
                <p class="mt-2">Your download is being prepared. This process may take several minutes. Please do not
                    refresh or close this page.</p>
            </div>

            <!-- 任务状态展示 -->
            <div v-if="showTaskStatus" class="mt-5">
                <h5 class="fw-bold">Task Status</h5>
                <ul class="list-group">
                    <li v-for="(stage, index) in stages" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center" :class="{
                            'bg-success text-white': stage.status === 'completed',
                            'bg-info text-white': stage.status === 'processing',
                        }">
                        {{ stage.name }}
                        <span v-if="stage.status === 'completed'">&#10004;</span>
                        <span v-else-if="stage.status === 'processing'">&#x23F3;</span>
                        <span v-else>&#10060;</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "CryptoDownload",
    data() {
        const today = new Date().toISOString().split("T")[0];
        return {
            exchanges: [
                { value: 'binance', name: 'Binance' },
                { value: 'okex', name: 'OKX' },
                { value: 'huobi', name: 'Huobi Global' },
                { value: 'coinbase', name: 'Coinbase Pro' }
            ],
            pairs: [
                { value: 'ADA/USDT', name: 'ADA/USDT' },
                { value: 'ATOM/USDT', name: 'ATOM/USDT' },
                { value: 'AVAX/USDT', name: 'AVAX/USDT' },
                { value: 'BNB/USDT', name: 'BNB/USDT' },
                { value: 'BTC/USDT', name: 'BTC/USDT' },
                { value: 'BCH/USDT', name: 'BCH/USDT' },
                { value: 'COMP/USDT', name: 'COMP/USDT' },
                { value: 'DOGE/USDT', name: 'DOGE/USDT' },
                { value: 'DOT/USDT', name: 'DOT/USDT' },
                { value: 'EOS/USDT', name: 'EOS/USDT' },
                { value: 'ETC/USDT', name: 'ETC/USDT' },
                { value: 'ETH/USDT', name: 'ETH/USDT' },
                { value: 'FIL/USDT', name: 'FIL/USDT' },
                { value: 'ICP/USDT', name: 'ICP/USDT' },
                { value: 'LINK/USDT', name: 'LINK/USDT' },
                { value: 'LTC/USDT', name: 'LTC/USDT' },
                { value: 'MATIC/USDT', name: 'MATIC/USDT' },
                { value: 'NEAR/USDT', name: 'NEAR/USDT' },
                { value: 'QNT/USDT', name: 'QNT/USDT' },
                { value: 'SOL/USDT', name: 'SOL/USDT' },
                { value: 'TRX/USDT', name: 'TRX/USDT' },
                { value: 'UNI/USDT', name: 'UNI/USDT' },
                { value: 'WBTC/USDT', name: 'WBTC/USDT' },
                { value: 'XLM/USDT', name: 'XLM/USDT' },
                { value: 'XMR/USDT', name: 'XMR/USDT' },
                { value: 'XRP/USDT', name: 'XRP/USDT' },
                { value: 'XTZ/USDT', name: 'XTZ/USDT' },
                { value: 'YFI/USDT', name: 'YFI/USDT' },
                { value: 'ZEC/USDT', name: 'ZEC/USDT' },
                { value: 'ZIL/USDT', name: 'ZIL/USDT' }
            ],
            selectedExchange: "binance",
            selectedPair: "BTC/USDT",
            timeframes: [
                { value: "1m", name: "1 Minute" },
                { value: "5m", name: "5 Minutes" },
                { value: "15m", name: "15 Minutes" },
                { value: "1h", name: "1 Hour" },
                { value: "1d", name: "1 Day" },
            ],
            selectedTimeframe: "1m",
            startDate: today,
            endDate: today,
            currentDate: today,
            isProcessing: false,
            showTaskStatus: false,
            showPaymentSuccess: false,
            stages: [
                { name: "Submit Task", status: "pending" },
                { name: "Awaiting Payment", status: "pending" },
                { name: "Queue Task", status: "pending" },
                { name: "Fetch Exchange Data", status: "pending" },
                { name: "Generate CSV File", status: "pending" },
                { name: "Complete", status: "pending" },
            ],
            taskId: null,
            showPaymentModal: false,
            paymentCost: 0,
            totalBars: 0,
            paymentApprovalUrl: ""
        };
    },
    computed: {
        isDateValid() {
            return this.startDate <= this.endDate && this.endDate <= this.currentDate;
        },
    },
    mounted() {
        // 处理PayPal回调
        const urlParams = new URLSearchParams(window.location.search);
        const paymentStatus = urlParams.get('payment');
        const taskId = urlParams.get('taskId');
        const paymentId = urlParams.get('paymentId');
        const payerId = urlParams.get('PayerID');

        if (paymentStatus === 'success' && taskId && paymentId && payerId) {
            // 从URL参数恢复查询条件
            const exchange = urlParams.get('exchange');
            const pair = urlParams.get('pair');
            const timeframe = urlParams.get('timeframe');
            const startDate = urlParams.get('startDate');
            const endDate = urlParams.get('endDate');

            if (exchange) this.selectedExchange = exchange;
            if (pair) this.selectedPair = pair;
            if (timeframe) this.selectedTimeframe = timeframe;
            if (startDate) this.startDate = startDate;
            if (endDate) this.endDate = endDate;

            this.handlePayPalSuccess(paymentId, payerId, taskId);
        } else if (paymentStatus === 'cancel') {
            this.handlePaymentCancel();
        }
    },
    methods: {
        async notifyPaymentCancellation() {
            try {
                await axios.post('/api/payment-cancelled', {
                    taskId: this.taskId
                });
            } catch (error) {
                console.error('Error notifying cancellation:', error);
            }
        },

        handlePaymentCancel() {
            // 清理URL
            const cleanUrl = window.location.origin + window.location.pathname;
            window.history.replaceState({}, document.title, cleanUrl);

            // 重置所有支付相关状态
            this.isProcessing = false;
            this.showPaymentModal = false;
            this.paymentCost = 0;
            this.taskId = null;

            // 显示用户友好的消息
            this.showCancellationMessage();
            // 调用API通知后端支付取消
            this.notifyPaymentCancellation();
        },

        showCancellationMessage() {
            this.$bvModal.msgBoxOk('Your payment process was cancelled. You can restart the download anytime.', {
                title: 'Payment Cancelled',
                okTitle: 'OK',
                size: 'md'
            });
        },

        async startDownload() {
            gtag('event', 'download', {
                'task_id': this.taskId,
                'exchange': this.exchange || '',
                'pair': this.pair || '',
                'timeframe': this.timeframe || '',
                'start_date': this.startDate || '',
                'end_date': this.endDate || ''
            });
            if (!this.isDateValid) {
                alert("Invalid date range! Please ensure Start Date <= End Date <= Current Date.");
                return;
            }

            this.isProcessing = true;
            this.showTaskStatus = false;
            this.showPaymentSuccess = false;
            this.resetStages();

            try {
                const params = {
                    exchange: this.selectedExchange,
                    pair: this.selectedPair,
                    timeframe: this.selectedTimeframe,
                    start_date: this.startDate,
                    end_date: this.endDate,
                };

                const response = await axios.post("/api/start-download", params);

                if (response.data.success) {
                    this.handleTask(response.data.taskId);
                } else if (response.data.payment_required) {
                    this.paymentCost = response.data.cost;
                    this.taskId = response.data.taskId;
                    this.totalBars = response.data.total_bars;
                    this.showPaymentModal = true;
                }
            } catch (error) {
                this.handleError(error);
            }
        },

        async proceedToPayment() {
            try {
                this.isProcessing = true;
                this.showPaymentModal = false;

                // 将当前查询参数添加到支付请求中
                const response = await axios.post("/api/create-payment", {
                    taskId: this.taskId,
                    cost: this.paymentCost,
                    totalBars: this.totalBars,
                    exchange: this.selectedExchange,
                    pair: this.selectedPair,
                    timeframe: this.selectedTimeframe,
                    startDate: this.startDate,
                    endDate: this.endDate
                });

                // 重定向到PayPal支付页面
                window.location.href = response.data.approval_url;
            } catch (error) {
                this.handleError(error);
            }
        },

        cancelPayment() {
            this.showPaymentModal = false;
            this.isProcessing = false;
        },

        handleError(error) {
            if (error.response?.data?.message) {
                alert(error.response.data.message);
            } else {
                alert("Operation Failed: " + (error.message || "Unknown error"));
            }
            this.isProcessing = false;
        },

        handleTask(taskId) {
            this.taskId = taskId;
            this.showTaskStatus = true;
            const interval = setInterval(async () => {
                try {
                    const statusResponse = await axios.get("/api/download-status", {
                        params: { taskId: this.taskId },
                    });
                    this.updateStages(statusResponse.data.currentStage);

                    if (statusResponse.data.currentStage === "Complete") {
                        clearInterval(interval);
                        this.isProcessing = false;
                        this.downloadCSV();
                    } else if (statusResponse.data.currentStage === "Failed") {
                        clearInterval(interval);
                        this.isProcessing = false;
                        alert("Task failed: " + (statusResponse.data.error || ""));
                    }
                } catch (error) {
                    clearInterval(interval);
                    this.isProcessing = false;
                    alert("Error fetching status");
                }
            }, 3000);
        },

        resetStages() {
            this.stages.forEach((stage) => (stage.status = "pending"));
        },

        updateStages(currentStage) {
            const currentStageIndex = this.stages.findIndex(stage => stage.name === currentStage);

            if (currentStageIndex === -1) {
                console.error(`Unknown stage: ${currentStage}`);
                return;
            }

            this.stages[currentStageIndex].status = "processing";

            for (let i = 0; i < currentStageIndex; i++) {
                this.stages[i].status = "completed";
            }

            if (currentStageIndex === this.stages.length - 1) {
                for (let i = 0; i < this.stages.length; i++) {
                    this.stages[i].status = "completed";
                }
            }
        },

        async downloadCSV() {
            try {
                const response = await axios.get("/api/download-csv", {
                    params: { taskId: this.taskId },
                    responseType: "blob",
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${this.taskId}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                alert("Failed to download CSV!");
                console.error("Error downloading CSV:", error);
            }
        },

        async handlePayPalSuccess(paymentId, payerId, taskId) {
            try {
                this.isProcessing = true;
                this.taskId = taskId;
                this.showPaymentSuccess = true;

                const response = await axios.post("/api/execute-payment", {
                    paymentId: paymentId,
                    payerId: payerId,
                    taskId: taskId
                });

                // 从响应中获取并更新总数据条数和总价格
                this.totalBars = response.data.total_bars || this.totalBars;
                this.paymentCost = response.data.cost || this.paymentCost;

                // 发送 GA4 购买事件
                gtag('event', 'purchase_csv', {
                    'transaction_id': this.taskId,
                    'value': this.paymentCost,
                    'currency': 'USD',
                    'items': [{
                        'item_name': 'purchase_csv',
                        'item_id': '001',
                        'task_id': this.taskId,
                        'exchange': this.exchange || '',
                        'pair': this.pair || '',
                        'timeframe': this.timeframe || '',
                        'start_date': this.startDate || '',
                        'end_date': this.endDate || '',
                        'total_bars': this.totalBars || 0,
                        'price': this.paymentCost,
                        'quantity': 1
                    }]
                });

                // 从URL参数恢复查询条件
                const urlParams = new URLSearchParams(window.location.search);
                const exchange = urlParams.get('exchange');
                const pair = urlParams.get('pair');
                const timeframe = urlParams.get('timeframe');
                const startDate = urlParams.get('startDate');
                const endDate = urlParams.get('endDate');

                if (exchange) this.selectedExchange = exchange;
                if (pair) this.selectedPair = pair;
                if (timeframe) this.selectedTimeframe = timeframe;
                if (startDate) this.startDate = startDate;
                if (endDate) this.endDate = endDate;

                this.showTaskStatus = true;
                this.handleTask(taskId);

                // 清除URL中的支付参数，保留其他查询参数
                const cleanUrl = new URL(window.location.href);
                cleanUrl.searchParams.delete('payment');
                cleanUrl.searchParams.delete('paymentId');
                cleanUrl.searchParams.delete('PayerID');
                window.history.replaceState({}, document.title, cleanUrl.toString());
            } catch (error) {
                this.handleError(error);
            }
        }
    },
};
</script>

<style scoped>
.btn-danger {
    background-color: #b71c1c;
    border-color: #b71c1c;
}

.list-group-item {
    font-weight: 500;
}

.modal-backdrop {
    opacity: 0.5;
}

.alert {
    padding: 1rem;
    border-radius: 0.25rem;
}
</style>