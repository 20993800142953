import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 导入 Bootstrap 5
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// 导入 router.js
import router from './router' // 确保 router.js 文件位于 src 目录中

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router, // 注册路由
  render: h => h(App),
}).$mount('#app')
